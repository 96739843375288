import React from 'react';
import queryString from 'query-string';
import {
  getIcon, fromMobileApp, isPOW, getAppTheme, isWebView
} from '../config/utils';
import classes from './classes.module.scss';
import { paymentSourcesTheme, appThemes } from '../config/const';
import AddPaymentMethod from './add_payment_method';
import AppGeneralPopup from '../app_general_popup';

const requireContext = require.context('./img', false, /\.svg$/);

export const getReturnTo = () => (queryString.parse(window.location.search) || {}).return_to;
export const getForceAddKind = () => (queryString.parse(window.location.search)
  || {}).force_add_kind;
export const getShouldAutoSelect = () => (queryString.parse(window.location.search)
|| {}).auto_select_payment;

const addingPPFromMobile = ({ kind }) => fromMobileApp && kind === 'pp';

const appTheme = getAppTheme();

const generateSourcesListLoading = () => {
  const sourcesListLoading = [];
  for (let i = 0; i < 3; i += 1) {
    sourcesListLoading.push({
      kind: 'loading', expired: null, isDefault: null, id: i
    });
  }
  return sourcesListLoading;
};

const notHavePaymentMethods = ({ existing }) => existing && existing.length < 1;

const getPlaceholderText = (t) => {
  if (isPOW()) return t('purchase.your_default_payment_method_is_inapp_purchases');
  return t('purchase.you_currently_have_no_saved_payment_methods');
};

export const getPaymentMethodsContentContainerStyle = ({ managingSource }) => {
  if (!managingSource) return {};
  return { background: 'var(--app-background-color)', height: 'unset' };
};

const getSourcesListcontainerClassName = ({ theme }) => {
  if (theme === paymentSourcesTheme.purchase) return classes.callSourcesContainer;
  return classes.sourcesContainer;
};

const shouldIgnoreAddPPOption = ({ kind, theme, isPayg }) => {
  if (kind !== 'pp') return false;
  if (isPayg) return true;
  return theme === paymentSourcesTheme.wallet;
};

const shouldIgnoreAddInAppption = ({ theme, setInAppPaymentOptions }) => {
  if (!isPOW() || !setInAppPaymentOptions) return true;
  return theme === paymentSourcesTheme.wallet;
};

const getAddCardHeaderBtnHolder = (existing, renderAddCardHeaderBtn) => (existing && existing.length > 0 ? renderAddCardHeaderBtn : null);

export const titleDataHandler = ({
  managingSource, setTitleData, doneManagingPaymentSource, doneChangingPaymentSource, theme,
  renderAddCardHeaderBtn, t, history, location, existing
}) => {
  if (managingSource) {
    setTitleData({ backArrowHandler: doneManagingPaymentSource, titleText: t('purchase.payment_method_info') });
    return;
  }
  if (theme === paymentSourcesTheme.purchase) {
    let backArrowHandler = doneChangingPaymentSource;
    if (getShouldAutoSelect()) {
      backArrowHandler = () => {
        // need for support old app rout
        history({ search: queryString.stringify({ state: 'user_close', ...(queryString.parse(location.search)) }) });
        doneChangingPaymentSource();
      };
    }
    setTitleData({
      backArrowHandler,
      titleText: t('purchase.use_a_different_payment_method'),
      titleTextStyle: {
        fontSize: '16px', justifyContent: 'flex-start', position: 'unset', paddingLeft: '15px'
      }
    });
    return;
  }
  if (theme === paymentSourcesTheme.wallet) {
    setTitleData({ titleText: t('purchase.payment_methods'), addCardHeaderBtnHolder: getAddCardHeaderBtnHolder(existing, renderAddCardHeaderBtn) });
  }
};

const renderNotHavePaymentMethods = ({ renderAddCardBottomBtn, t }) => (
  <div className={ classes.placeholderContainer }>
    <img
      className={ classes.placeholderImg }
      src={ getIcon({
        iconName: 'placeholder', requireContext, forAppContext: true, appTheme: appTheme || appThemes.light
      }) }
      alt=""
    />
    <div className={ classes.placeholderText }>{ getPlaceholderText(t) }</div>
    {renderAddCardBottomBtn(true)}
  </div>
);

const renderSourcesListLoading = ({ theme, renderSource }) => (
  <div className={ getSourcesListcontainerClassName({ theme }) }>
    { generateSourcesListLoading().map((s) => renderSource(s))}
  </div>
);

const renderSourcesListContent = ({
  existing, renderSource, renderAddCardBottomBtn, t
}) => {
  if (notHavePaymentMethods({ existing })) return renderNotHavePaymentMethods({ renderAddCardBottomBtn, t });
  existing.forEach((item, i) =>  {
    if (item.isDefault) {
      existing.splice(i, 1);
      existing.unshift(item);
    }
  });
  return existing.map((s) => renderSource(s));
};

export const renderSourcesList = ({
  loading, existing, theme, renderSource, renderAddCardBottomBtn, t
}) => {
  if (loading || !existing) return renderSourcesListLoading({ theme, renderSource });
  return (
    <div className={ getSourcesListcontainerClassName({ theme }) }>
      { renderSourcesListContent({
        existing, renderSource, renderAddCardBottomBtn, t
      }) }
    </div>
  );
};

const addSourceForKindLoading = ({ theme, kind, loading }) => {
  if (theme !== paymentSourcesTheme.purchase) return null;
  return (
    <AddPaymentMethod
      kind={ kind }
      key={ kind }
      theme={ theme }
      loading={ loading }
    />
  );
};

const renderAddInAppSource = ({ theme, kind, setInAppPaymentOptions }) => (
  <AddPaymentMethod
    addSource={ setInAppPaymentOptions }
    kind={ kind }
    key={ kind }
    theme={ theme }
  />
);

const renderAddSourceForKindCCOrPP = ({
  available, kind, theme, onAddSource, existing, isPayg
}) => {
  if (!available) return null;
  const source = available.find((s) => s.kind === kind);
  if (!source || shouldIgnoreAddPPOption({ kind: source.kind, theme, isPayg })) return null;
  return (
    <AddPaymentMethod
      addSource={ onAddSource }
      { ...source }
      key={ source.kind }
      theme={ theme }
      existing={ existing }
    />
  );
};

export const renderAddSourceForKindHandler = ({
  kind, available, theme, onAddSource, existing, setInAppPaymentOptions, loading, isPayg
}) => {
  if (loading) return addSourceForKindLoading({ theme, kind, loading });
  if (kind === 'iap' && !shouldIgnoreAddInAppption({ theme, setInAppPaymentOptions })) return renderAddInAppSource({ theme, kind, setInAppPaymentOptions });
  return renderAddSourceForKindCCOrPP({
    available, kind, theme, onAddSource, existing, isPayg
  });
};

export const onPaymentMethodSelectedHandler = ({
  hidePopup, returnTo, history, location
}) => {
  if (hidePopup) hidePopup();
  if (returnTo) window.location.href = returnTo;
  history({
    search: queryString.stringify({ state: 'completed', ...(queryString.parse(location.search)) })
  });
};

export const handleDoneAddCardHandler = ({
  theme, existing, doneChangingPaymentSource, doneAddCard, showPaymentSources
}) => {
  if (theme === paymentSourcesTheme.purchase && notHavePaymentMethods({ existing })) {
    const closeAddCard = () => {
      doneChangingPaymentSource();
      doneAddCard();
    };
    return closeAddCard();
  }
  if (showPaymentSources) {
    const closeAddCard = () => {
      showPaymentSources(false);
      doneAddCard();
    };
    return closeAddCard();
  }
  return doneAddCard();
};

export const trackPaymentSourcesEventHandler = ({
  loaded, existing, needTrackPaymentSourcesEvent, trackPaymentSourcesEvent, clickSource,
  setNeedTrackPaymentSourcesEvent, theme, loading
}) => {
  if (theme === paymentSourcesTheme.purchase && existing && existing.length <= 0) return;
  if (loaded && !loading && needTrackPaymentSourcesEvent) {
    trackPaymentSourcesEvent(clickSource);
    setNeedTrackPaymentSourcesEvent(false);
  }
};

export const onAddSourceHandler = ({
  kind, history, addSource, showPaymentSources, location
}) => {
  if (addingPPFromMobile({ kind })) {
    history({ search: queryString.stringify({ state: 'choose_paypal', ...(queryString.parse(location.search)) }) });
  }
  if (kind === 'cc' && showPaymentSources) {
    const addCreditCard = () => {
      showPaymentSources('cc');
      addSource(kind);
    };
    return addCreditCard();
  }
  return addSource(kind);
};

export const onDefault = (source, onMakeDefault, history, location, setShowToast, theme,  updateDefault, clickSource) => {
  if (updateDefault) {
    updateDefault(source);
    return;
  }
  const makeDefParams = {
    id:source.id, kind:source.kind, type:source.context.type, clickSource
  };
  onMakeDefault(history, location, makeDefParams);
  if (theme === paymentSourcesTheme.wallet) {
    setShowToast(true);
    setTimeout(() => { setShowToast(false); }, 2000);
  }
};

export const renderPaymentMethodsContent = ({
  managingSource, theme, renderManagingSource, loading, existing, renderSource, t,
  renderAddCardBottomBtn, renderAddSourceForKind, renderAddCreditCard
}) => {
  if (managingSource && theme === paymentSourcesTheme.wallet) return renderManagingSource();
  return (
    <>
      { renderSourcesList({
        loading, existing, theme, renderSource, renderAddCardBottomBtn, t
      }) }
      { renderAddCardBottomBtn() }
      { isWebView() && !fromMobileApp ? null :  renderAddSourceForKind('pp') }
      { renderAddSourceForKind('iap') }
      { renderAddCreditCard() }
    </>
  );
};

export const renderAddCreditCardHandler = ({
  addCreditCard, theme, id, changingSource
}) => {
  if (!id && changingSource !== 'cc') return null;
  if (theme === paymentSourcesTheme.wallet) {
    return <AppGeneralPopup visible={ !!id }>{ addCreditCard }</AppGeneralPopup>;
  }
  return addCreditCard;
};

export const needRenderOnlyAddCreditCard = ({ addingCardData, theme, changingSource }) => {
  const { id } = addingCardData || {};
  return theme === paymentSourcesTheme.purchase && (id || changingSource === 'cc');
};
